import React, { Component, Fragment } from 'react'

/** Component */
import {
	Header,
	MyImage,
	MyVideo,
	Quote,
	Copyright,
	Navigation,
	NavBarEn,
	SkillsEnDeltu,
} from 'components'

/** Images */
import { deltu, nora } from 'images'

export default class DeltuEn extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frlink: '/fr/deltu',
			headertitle: 'Deltu',
			headerpresentation:
				'Deltu is a delta robot with a strong personality that interacts with humans through two iPads. Depending on his mood, it plays with you but if you make too much mistakes, Deltu might just get upset and decide to ignore you. Frustrated, Deltu will leave the game and take some selfies to post on instagram.',

			quotelink: 'https://www.gizmodo.co.uk/2016/10/this-gaming-robot-is-a-moody-narcissist/',
			quotetext: 'Do not piss Deltu',
			quotename: 'Gizmodo',

			quotelink2:
				'https://www.frameweb.com/news/technology-moves-reacts-and-pretends-to-be-human---so-what-can-it-do-for-us-',
			quotetext2: 'The gaming machine has serious mood swings',
			quotename2: 'Frame Magazine - 114',

			quotelink3:
				'https://www.dailymail.co.uk/sciencetech/article-3820353/The-demanding-gaming-robot-ignore-opponent-selfies-upset-it.html#ixzz4dIZpH9Mg',
			quotetext3:
				"Robots have learned to outsmart humans in a number of common games - and now, if your skills aren't up to par, they just might get 'upset' with you",
			quotename3: 'Daily Mail',

			videochanel: 'vimeo',
			idvideo: '184652558',
			credittext: 'Image by Younès Klouche',

			credittext2: 'El Hormiguero 3.0 show - with Norah Jones - October 2016',

			copyright: '© 2017 Alexia Lechot / ECAL',

			previouslink: '/en/alexialechot',
			nextlink: '/en/lazer',
		}
	}
	render() {
		const {
			frlink,
			headertitle,
			headerpresentation,
			quotelink,
			quotetext,
			quotename,
			quotelink2,
			quotetext2,
			quotename2,
			quotelink3,
			quotetext3,
			quotename3,
			credittext,
			credittext2,
			videochanel,
			idvideo,
			previouslink,
			copyright,
			nextlink,
		} = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBarEn frenshlink={frlink} />
				<div className="bg-white">
					<Header title={headertitle} text={headerpresentation} />
					<Quote link={quotelink} quote={quotetext} name={quotename} />
					<MyVideo
						channel={videochanel}
						vimeoId={idvideo}
						image={deltu}
						credit={credittext}
					/>
					<Quote link={quotelink2} quote={quotetext2} name={quotename2} />
					<MyImage image={nora} credit={credittext2} />
					<Quote link={quotelink3} quote={quotetext3} name={quotename3} />
					<div className="mt-6 border-top border-3 border-secondary" />
					<SkillsEnDeltu />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

import React, { Fragment } from 'react'

/** Component */
import { Cell } from 'components'

/** Images */
import {
	arduino,
	bootstrap,
	cpp,
	c4d,
	cc,
	csharp,
	figma,
	finalcutpro,
	gitkraken,
	html,
	js,
	json,
	qt,
	react,
	sass,
	sketch,
	unity,
	vscode,
	wordpress,
	xcode,
	treed,
	agile,
	concept,
	dev,
	graphic,
	iot,
	ux,
	video,
} from 'images'

const SkillsEn = ({ title }) => {
	return (
		<Fragment>
			<div className="container my-6">
				{/* TITRE */}
				<h2 className="text-secondary text-center mb-4">{title}</h2>

				{/* SKILLS */}
				<div className="SectionCellGroup mx-auto">
					<Cell title="UX & UI Design" image={ux} />
					<Cell title="Agile methodology" image={agile} />
					<Cell title="Front-end development" image={dev} />
					<Cell title="Graphic Design" image={graphic} />
					<Cell title="IOT Prototype" image={iot} />
					<Cell title="3D modeling" image={treed} />
					<Cell title="Video editing" image={video} />
					<Cell title="Concept development" image={concept} />
				</div>
				{/* SOFTWARES */}
				<div className="SectionCellGroup mx-auto mt-5">
					<Cell title="Sketch" image={sketch} />
					<Cell title="Adobe Creative Suite" image={cc} />
					<Cell title="Figma" image={figma} />
					<Cell title="Cinema 4D" image={c4d} />
					<Cell title="Final Cut Pro" image={finalcutpro} />
					<Cell title="Wordpress" image={wordpress} />
					<Cell title="Visual Studio Code" image={vscode} />
					<Cell title="GitKraken" image={gitkraken} />
					<Cell title="QT Creator" image={qt} />
					<Cell title="Unity" image={unity} />
					<Cell title="Xcode" image={xcode} />
					<Cell title="Arduino" image={arduino} />
				</div>
				{/* PROGRAMING */}
				<div className="SectionCellGroup mx-auto mt-5">
					<Cell title="React / Gatsby / Vue JS" image={react} />
					<Cell title="SASS / CSS3" image={sass} />
					<Cell title="Bootstrap" image={bootstrap} />
					<Cell title="HTML5" image={html} />
					<Cell title="JS ES6" image={js} />
					<Cell title="Static Data JSON" image={json} />
					<Cell title="C++ (basics)" image={cpp} />
					<Cell title="C# (basics)" image={csharp} />
				</div>
			</div>
		</Fragment>
	)
}
export default SkillsEn

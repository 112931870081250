import React, { Fragment } from 'react'

const Copyright = ({ text }) => {
	return (
		<Fragment>
			<div className="container mt-6">
				<h3 className="text-secondary">{text}</h3>
			</div>
		</Fragment>
	)
}
export default Copyright

import React, { Fragment } from 'react'

const Header = ({ title, text, image }) => {
	return (
		<Fragment>
			{/* USE IN MY PROJECTS */}
			<div className="bg-dark pt-11 pb-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<h1>{title}</h1>
							<h4>{text}</h4>
						</div>
					</div>
				</div>
			</div>
			<img className="img-fluid FullImage" src={image} alt={image} />
		</Fragment>
	)
}
export default Header

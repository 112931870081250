import React, { Component, Fragment } from 'react'

/** Component */
import {
	Header,
	MyImage,
	MySmallSvg,
	MyVideo,
	Copyright,
	Navigation,
	NavBarEn,
	SkillsEnUnityApp,
} from 'components'

/** Images */
import { lazer, lazer1, lazer2, appstore } from 'images'

export default class LazerEn extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frlink: '/fr/lazer',
			headertitle: 'Lazer',
			headertext:
				'Lazer is an experimental game inspired by oldies such as Vectrex. The goal is to immerse yourself in a minimalist world in which you must avoid lasers to survive.',

			videochanel: 'vimeo',
			idvideo: '161785147',

			appstorlink: 'https://itunes.apple.com/fr/app/lazer-game/id1090341180?mt=8',

			copyright: '© 2016 Alexia Lechot / ECAL',
			previouslink: '/en/deltu',
			nextlink: '/en/www',
		}
	}
	render() {
		const {
			frlink,
			headertitle,
			headertext,
			appstorlink,
			videochanel,
			idvideo,
			previouslink,
			copyright,
			nextlink,
		} = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBarEn frenshlink={frlink} />

				<div className="bg-white">
					<Header title={headertitle} text={headertext} image={lazer1} />
					<MyVideo channel={videochanel} vimeoId={idvideo} image={lazer2} />
					<MySmallSvg image={appstore} link={appstorlink} />
					<MyImage image={lazer} />
					<SkillsEnUnityApp />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

import React, { Fragment } from 'react'

const HeaderBig = ({ title1, title2, title3 }) => {
	return (
		<Fragment>
			{/* USE IN MY MAINS PAGES */}
			<div className="mainHeaderText py-5 CenterDiv">
				<div className="container text-left">
					<h2>{title1}</h2>
					<h2>{title2}</h2>
					<h2>{title3}</h2>
				</div>
			</div>
		</Fragment>
	)
}
export default HeaderBig

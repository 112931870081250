import React, { Fragment } from 'react'

const MyText = ({ title, text }) => {
	return (
		<Fragment>
			<div className="container mt-6">
				<div className="row">
					<div className="col-lg-11 mx-auto">
						<h2 className="text-center text-secondary">{title}</h2>
						<h4 className="text-dark">{text}</h4>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default MyText

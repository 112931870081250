import React, { Fragment } from 'react'

const MyFullImage = ({ image }) => {
	return (
		<Fragment>
			<div className="mt-6">
				<img className="img-fluid FullImage" src={image} alt={image} />
			</div>
		</Fragment>
	)
}
export default MyFullImage

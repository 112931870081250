import React, { Component, Fragment } from 'react'

/** Component */
import {
	Header,
	MyImage,
	MyFullImage,
	MyText,
	Copyright,
	Navigation,
	NavBar,
	SkillsRamayana,
} from 'components'

/** Images */
import { hanuman, ravana } from 'images'

export default class Ramayana extends Component {
	constructor(props) {
		super(props)
		this.state = {
			enlink: '/en/ramayana',
			headertitle: 'Ramayana',
			headerpresentation:
				"Une application AR (Réalité Augmentée) qui reconnait les masques Ramayana et donne des informations sur chacun d'entre eux. Etait exposé au musée Art et Collection à Crans-Montana",
			mytexttitle: 'Reconnaissance 3D',
			mytext:
				"Lorsque les visiteurs veulent plus d'informations sur un masque, ils le placent devant la caméra de l'iPad. Le masque est reconnu par l'application qui affiche ses informations.",
			copyright: '© 2018 Alexia Lechot / travail pour Créactif sarl / picto par David Bérod',
			previouslink: '/fr/www',
			nextlink: '/fr/alexialechot',
		}
	}
	render() {
		const {
			enlink,
			headertitle,
			headerpresentation,
			mytexttitle,
			mytext,
			previouslink,
			copyright,
			nextlink,
		} = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBar englishlink={enlink} />

				<div className="bg-white">
					<Header title={headertitle} text={headerpresentation} />
					<MyImage image={hanuman} />
					<MyText title={mytexttitle} text={mytext} />
					<MyFullImage image={ravana} />
					{/* <MyText title={mytexttitle2} text={mytext2} />
					<MyImage image={arbre} /> */}
					<SkillsRamayana />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

import React, { Fragment } from 'react'

/** Component */
import { Cell } from 'components'

/** Images */
import { csharp, unity, xcode, concept, dev, ux } from 'images'

const SkillsEnRamayana = () => {
	return (
		<Fragment>
			<div className="container my-6">
				{/* TITRE */}
				<h2 className="text-secondary text-center mb-4">Role</h2>

				{/* SKILLS */}
				<div className="SectionCellGroup mx-auto">
					<Cell title="Concept development" image={concept} />
					<Cell title="UX Design" image={ux} />
					<Cell title="Application development" image={dev} />
					<Cell title="Unity" image={unity} />
					<Cell title="Xcode" image={xcode} />
					<Cell title="C#" image={csharp} />
				</div>
			</div>
		</Fragment>
	)
}
export default SkillsEnRamayana

import React, { Component, Fragment } from 'react'

export default class Footer extends Component {
	render() {
		return (
			<Fragment>
				<div className="text-center bg-secondary" id="Footer">
					<div className="container pt-6 pb-2">
						{/* CONTACT */}
						{/* SOCIAL LINKS */}
						<div className="row mt-4">
							<div className="col text-center text-white ActiveHoverInfo">
								{/* MAIL */}
								<a className="mx-3 BlackHover" href="mailto:alexia@krakenwave.ch">
									<h4 className="OpacityInfo text-dark">alexia@krakenwave.ch</h4>
									<i className="fas fa-paper-plane fa-3x mt-2" />
								</a>
								{/* LINKEDIN */}
								<a
									className="mx-3 BlackHover"
									href="https://www.linkedin.com/in/alexialechot/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<h4 className="OpacityInfo text-dark">Linkedin</h4>
									<i className="fab fa-linkedin-in fa-3x mt-2" />
								</a>
								{/* DRIBBBLE */}
								<a
									className="mx-3 BlackHover"
									href="https://dribbble.com/krakenwave"
									target="_blank"
									rel="noopener noreferrer"
								>
									<h4 className="OpacityInfo text-dark">Dribbble</h4>
									<i className="fab fa-dribbble fa-3x mt-2" />
								</a>
								{/* GITHUB */}
								{/* <a
									className="mx-3 BlackHover"
									href="https://github.com/alexialechot"
									target="_blank"
									rel="noopener noreferrer"
								>
									<h4 className="OpacityInfo text-white">Github</h4>
									<i className="fab fa-github fa-3x mt-2" />
								</a> */}
							</div>
						</div>
						{/* COPYRIGHT */}
						<div className="text-white pt-6">
							<h3>
								© 2021 KRAKENWAVE
								{/* <img className="text-center my-2" src={logo} alt="logo" height="25"/> */}
							</h3>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

import React, { Component, Fragment } from 'react'

/** Component */
import { Header, Copyright, Navigation, NavBar } from 'components'

/** Images */
import { after, before, lazer2 } from 'images'

export default class eMotion extends Component {
	constructor(props) {
		super(props)
		this.state = {
			enlink: '/en/emotion',
			headertitle: 'eMotion',
			headertext:
				"eMotion est l'application de contrôle de drone au sol de senseFly. J'ai eu la joie de la redesigner et de participer a l'integration avec l'aide des ingénieurs.",

			copyright: '© 2021  Alexia Lechot / senseFly',
			previouslink: '/fr/deltu',
			nextlink: '/fr/www',
		}
	}
	render() {
		const { enlink, headertitle, headertext, previouslink, copyright, nextlink } = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBar englishlink={enlink} />

				<Header title={headertitle} text={headertext} />

				<div className="container mt-sm-6 mt-3">
					<div id="beforeAfter" className="mx-1">
						<img
							className="MyImage shadowImage bottom img-fluid mx-auto"
							src={before}
						/>
						<img className="MyImage shadowImage top img-fluid mx-auto" src={after} />
					</div>
				</div>
				<section>
					<div className="slider">
						<input type="radio" name="slide_switch" id="id1" checked={true} />
						<label for="id1" className="mt-5">
							<img src={before} width="100" />
						</label>
						<img
							className="MyImage shadowImage bottom img-fluid mx-auto"
							src={before}
						/>

						<input type="radio" name="slide_switch" id="id2" />
						<label for="id2" className="mt-5">
							<img src={after} width="100" />
						</label>
						<img className="MyImage shadowImage bottom img-fluid mx-auto" src={after} />

						<input type="radio" name="slide_switch" id="id3" />
						<label for="id3" className="mt-5">
							<img src={lazer2} width="100" />
						</label>
						<img
							className="MyImage shadowImage bottom img-fluid mx-auto"
							src={lazer2}
						/>
					</div>
				</section>

				{/* <MySmallSvg image={appstore} link={appstorlink} />
					<MyImage image={lazer} />
					<SkillsUnityApp /> */}
				<Copyright text={copyright} />
				<Navigation previous={previouslink} next={nextlink} />
			</Fragment>
		)
	}
}

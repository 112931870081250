import React from 'react'
import ReactDOM from 'react-dom'

/** CSS */
import 'bootstrap/dist/css/bootstrap.css'

/** custom bootstrap */
import './scss/index.scss'
/** custom css */
import './index.css'
/** font-awesome */
import 'font-awesome/css/font-awesome.min.css'

/** jQuery first, then Popper.js, then Bootstrap JS */
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper-utils.js'
import 'bootstrap/dist/js/bootstrap.min.js'

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

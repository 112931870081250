import React, { Component, Fragment } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { Link } from 'react-router-dom'

export default class Navigation extends Component {
	scrollToTop() {
		//scroll at top of the page onClick
		scroll.scrollToTop()
	}
	render() {
		const { previous, next } = this.props
		return (
			<Fragment>
				<div className="bg-dark pt-4 pb-4">
					{/* NAVIGATION */}
					<div className="row">
						<div className="col text-center">
							{/* ARROW LEFT - PREVIOUS PAGE */}
							<Link
								className="btn BtnWhite rounded-circle RoundBtn mx-2"
								to={previous}
								onClick={this.scrollToTop}
							>
								<div className="LineHeight FontSize">&#8592;</div>
							</Link>

							{/* ARROW TOP */}
							<button
								type="button"
								className="btn BtnWhite rounded-circle RoundBtn BtnHoverBug mx-2"
								onClick={this.scrollToTop}
							>
								<div className="LineHeight FontSize">↑</div>
							</button>

							{/* ARROW RIGHT - NEXT PAGE */}
							<Link
								className="btn BtnWhite rounded-circle RoundBtn mx-2"
								to={next}
								onClick={this.scrollToTop}
							>
								<div className="LineHeight FontSize">&#8594;</div>
							</Link>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

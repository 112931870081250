import React, { Fragment } from 'react'

const MyImage = ({ credit, image }) => {
	return (
		<Fragment>
			<div className="container mt-sm-3 mt-2">
				<div className="mx-1">
					<img className="img-fluid card border-0 mx-auto" src={image} alt={image} />
					<h3 className="text-secondary pt-2">{credit}</h3>
				</div>
			</div>
		</Fragment>
	)
}
export default MyImage

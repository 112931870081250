import React, { Component, Fragment } from 'react'

/** Component */
import { ProjetsEn, HeaderBig, SkillsEn, Navigation, NavBarEn } from 'components'

export default class KrakenwaveEn extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frlink: '/fr',
			headertitle1: 'Alexia Lechot ',
			headertitle2: 'UX UI Designer &',
			headertitle3: 'Front-end integrator',
			ptitle: 'Some projects',
			skillstitle: 'My skills',
			previouslink: '/en/ramayana',
			nextlink: '/en/deltu',
		}
	}
	render() {
		const {
			frlink,
			ptitle,
			headertitle1,
			headertitle2,
			headertitle3,
			skillstitle,
			previouslink,
			nextlink,
		} = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBarEn frenshlink={frlink} />
				<div id="imageHeader" />
				<HeaderBig title1={headertitle1} title2={headertitle2} title3={headertitle3} />
				<ProjetsEn title={ptitle} />
				<div className="mt-6 border-top border-3 border-secondary" />
				<SkillsEn title={skillstitle} />
				<Navigation previous={previouslink} next={nextlink} />
			</Fragment>
		)
	}
}

import React, { Component, Fragment } from 'react'

/** Component */
import {
	Header,
	MyMultipleImage,
	Copyright,
	Navigation,
	NavBarEn,
	SkillsEnUnityApp,
} from 'components'

/** Images */
import { q1, q2, q3, q4 } from 'images'

export default class WwwEn extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frlink: '/fr/www',
			headertitle: 'www',
			headertext: 'A survey app with real-time results.',
			copyright: '© 2019 Alexia Lechot',
			previouslink: '/en/lazer',
			nextlink: '/en/ramayana',
		}
	}
	render() {
		const { frlink, headertitle, headertext, copyright, previouslink, nextlink } = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBarEn frenshlink={frlink} />

				<div className="bg-white">
					<Header title={headertitle} text={headertext} />
					<div className="columnImage">
						<MyMultipleImage image={q1} />
						<MyMultipleImage image={q2} />
						<MyMultipleImage image={q3} />
						<MyMultipleImage image={q4} />
					</div>
					<SkillsEnUnityApp />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

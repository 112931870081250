import React, { Component, Fragment } from 'react'

/** Component */
import {
	Header,
	MyImage,
	MyVideo,
	Quote,
	Copyright,
	Navigation,
	NavBar,
	SkillsDeltu,
} from 'components'

/** Images */
import { deltu, nora } from 'images'

export default class Deltu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			enlink: '/en/deltu',
			headertitle: 'Deltu',
			headerpresentation:
				"Deltu est un robot delta doté d'une forte personnalité, qui interagit avec les humains via deux iPad. En fonction de son humeur, il joue avec vous, mais si vous faites trop d'erreurs, Deltu risque de s'énerver et de décider de vous ignorer. Frustré, Deltu quittera le jeu et prendra quelques selfies pour les publier sur instagram.",

			quotelink: 'https://www.gizmodo.co.uk/2016/10/this-gaming-robot-is-a-moody-narcissist/',
			quotetext: "N'emmerdez pas Deltu",
			quotename: 'Gizmodo',

			quotelink2:
				'https://www.frameweb.com/news/technology-moves-reacts-and-pretends-to-be-human---so-what-can-it-do-for-us-',
			quotetext2: "La machine a de graves sautes d'humeur",
			quotename2: 'Frame Magazine - 114',

			quotelink3:
				'https://www.dailymail.co.uk/sciencetech/article-3820353/The-demanding-gaming-robot-ignore-opponent-selfies-upset-it.html#ixzz4dIZpH9Mg',
			quotetext3:
				'Les robots ont appris à déjouer les humains dans un certain nombre de jeux courants - et maintenant, si vos compétences ne sont pas à la hauteur, ils risquent de se fâcher contre vous',
			quotename3: 'Daily Mail',

			videochanel: 'vimeo',
			idvideo: '184652558',
			credittext: 'Image par Younès Klouche',

			credittext2: 'El Hormiguero 3.0 show - avec Norah Jones - Octobre 2016',

			copyright: '© 2017 Alexia Lechot / travail de bachelor ECAL',

			previouslink: '/fr/alexialechot',
			nextlink: '/fr/lazer',
		}
	}
	render() {
		const {
			enlink,
			headertitle,
			headerpresentation,
			quotelink,
			quotetext,
			quotename,
			quotelink2,
			quotetext2,
			quotename2,
			quotelink3,
			quotetext3,
			quotename3,
			credittext,
			credittext2,
			videochanel,
			idvideo,
			previouslink,
			copyright,
			nextlink,
		} = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBar englishlink={enlink} />
				<div className="bg-white">
					<Header title={headertitle} text={headerpresentation} />
					<Quote link={quotelink} quote={quotetext} name={quotename} />
					<MyVideo
						channel={videochanel}
						vimeoId={idvideo}
						image={deltu}
						credit={credittext}
					/>
					<Quote link={quotelink2} quote={quotetext2} name={quotename2} />
					<MyImage image={nora} credit={credittext2} />
					<Quote link={quotelink3} quote={quotetext3} name={quotename3} />
					<div className="mt-6 border-top border-3 border-secondary" />
					<SkillsDeltu />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

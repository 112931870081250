import React, { Component, Fragment } from 'react'
import ModalVideo from 'react-modal-video'

/** IMAGES */
import { iconPlay } from 'images'

export default class MyVideo extends Component {
	constructor() {
		super()
		this.state = {
			isOpen: false,
			autoplay: true,
		}
		this.openModal = this.openModal.bind(this)
	}

	openModal() {
		this.setState({ isOpen: true })
	}

	render() {
		const { channel, vimeoId, image, credit } = this.props
		return (
			<Fragment>
				<ModalVideo
					channel={channel}
					isOpen={this.state.isOpen}
					videoId={vimeoId}
					onClose={() => this.setState({ isOpen: false })}
				/>

				<div className="container mt-6 mx-auto">
					<div className="PlayGroup" onClick={this.openModal}>
						<div className="PlayButton shadow">
							<img className="ml-2 IconPlay" src={iconPlay} alt="iconPlay" />
						</div>
						<img
							className="shadowImage img-fluid card border-0 mx-auto"
							src={image}
							alt={image}
						/>
						<h3 className="text-secondary pt-2">{credit}</h3>
					</div>
				</div>
			</Fragment>
		)
	}
}

import 'aos/dist/aos.css'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import AOS from 'aos'
import 'aos/dist/aos.css'

/*
* Install the Library - Styled-Components
* Open the integrated Terminal (Command + Shift + ^)
* $ yarn add styled-components
*/

const CellGroup = styled.div`
	display: grid;
	grid-template-columns: 60px auto;
	grid-gap: 15px;
	align-items: center;
	max-width: 360px;

	@media (max-width: 800px) {
		grid-gap: 10px;
	}
`

const CellImage = styled.div`
	width: 45px;
	height: 45px;
	background: #f0f0f0;
	border-radius: 10px;
	background-image: url(${props => props.image});
	background-size: 25px;
	background-repeat: no-repeat;
	background-position: center;

	@media (max-width: 800px) {
		width: 40px;
		height: 40px;
		background-size: 23px;
	}
`

const CellTitle = styled.div`
	/* Add a line */
	color: black;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px 0;
`

export default class Skills extends Component {
	constructor(props, context) {
		super(props, context)
		AOS.init({
			once: false,
		})
	}
	componentWillReceiveProps() {
		AOS.refresh()
	}
	render() {
		const { image, title } = this.props
		return (
			<Fragment>
				<div data-aos="fade-up" data-aos-duration="2000">
					<CellGroup>
						<CellImage image={image} />
						<CellTitle>
							<h4 className="my-auto">{title}</h4>
						</CellTitle>
					</CellGroup>
				</div>
			</Fragment>
		)
	}
}

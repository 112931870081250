import React, { Fragment } from 'react'

const Quote = ({ link, quote, name }) => {
	return (
		<Fragment>
			<div className="container mt-6 mb-3">
				<div className="mx-4 my-auto text-center">
					<a href={link} target="_blank" rel="noopener noreferrer">
						<h5 className="text-secondary">"{quote}"</h5>
						<button type="button" className="btn BtnBlack mt-3">
							<div className="text-center mt-2 mx-2 my-2">
								<h3 className="NolineHeight font-weight-bold text-uppercase">
									{name}
								</h3>
							</div>
						</button>
					</a>
				</div>
			</div>
		</Fragment>
	)
}
export default Quote

import React, { Component, Fragment } from 'react'

/** Component */
import { Header, Copyright, Navigation, NavBar, SkillsUnityApp, MyMultipleImage } from 'components'

/** Images */
import { q1, q2, q3, q4 } from 'images'

export default class Www extends Component {
	constructor(props) {
		super(props)
		this.state = {
			enlink: '/en/www',
			headertitle: 'www',
			headertext: 'Une application de sondage avec les résultats en temps réel.',
			copyright: '© 2019 Alexia Lechot',
			previouslink: '/fr/lazer',
			nextlink: '/fr/ramayana',
		}
	}
	render() {
		const { enlink, headertitle, headertext, copyright, previouslink, nextlink } = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBar englishlink={enlink} />

				<div className="bg-white">
					<Header title={headertitle} text={headertext} />
					<div className="columnImage">
						<MyMultipleImage image={q1} />
						<MyMultipleImage image={q2} />
						<MyMultipleImage image={q3} />
						<MyMultipleImage image={q4} />
					</div>
					<SkillsUnityApp />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

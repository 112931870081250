import React, { Fragment } from 'react'

const MySmallSvg = ({ link, image }) => {
	return (
		<Fragment>
			<div className="container mt-6">
				<a className="mx-auto" href={link}>
					<img
						className="img-fluid card border-0 mx-auto SvgImage"
						src={image}
						alt={image}
						width="250"
					/>
				</a>
			</div>
		</Fragment>
	)
}
export default MySmallSvg

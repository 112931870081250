import React, { Component, Fragment } from 'react'

/** Component */
import {
	Header,
	MyImage,
	MyFullImage,
	MyText,
	Copyright,
	Navigation,
	NavBarEn,
	SkillsEnRamayana,
} from 'components'

/** Images */
import { hanuman, ravana } from 'images'

export default class RamayanaEn extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frlink: '/fr/ramayana',
			headertitle: 'Ramayana',
			headerpresentation:
				'An AR (Augmented Reality) application that recognizes Ramayana masks and gives information about each one. Was exhibited at the Art et Collection museum in Crans-Montana.',
			mytexttitle: '3D recognition',
			mytext:
				'When visitors want more information about a mask, they place it in front of the camera of the iPad. The mask is recognized by the application that displays its information.',
			copyright: '© 2018 Alexia Lechot / for Créactif sarl / picto by David Bérod',
			previouslink: '/en/www',
			nextlink: '/en/alexialechot',
		}
	}
	render() {
		const {
			frlink,
			headertitle,
			headerpresentation,
			mytexttitle,
			mytext,
			previouslink,
			copyright,
			nextlink,
		} = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBarEn frenshlink={frlink} />

				<div className="bg-white">
					<Header title={headertitle} text={headerpresentation} />
					<MyImage image={hanuman} />
					<MyText title={mytexttitle} text={mytext} />
					<MyFullImage image={ravana} />
					{/* <MyText title={mytexttitle2} text={mytext2} />
					<MyImage image={arbre} /> */}
					<SkillsEnRamayana />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

import React, { Fragment } from 'react'

/** Component */
import { Card } from 'components'

/** Images */
import { deltuMenu, lazerMenu, ramayanaMenu, surveyMenu } from 'images'

/** STATIC DATA */
// import staticdatapages from '../../staticdata/pagesenglish.json'
// define a link page to DB.JSON Page
// const pagesLink = staticdatapages.pages

const Projets = ({ title }) => {
	return (
		<Fragment>
			<div className="pt-6">
				{/* TITRE */}
				<div className="container">
					<h2 className="mb-4 text-secondary text-center">{title}</h2>
					<div className="mx-auto" id="CardGroup">
						{/* CARDS */}
						{/* {pagesLink.map(data => (
							<Card
								title={data.title}
								link={data.pathname}
								text={data.text}
								image={require(`./../../images/cards/${data.image}`).default}
								// Fix image - object.module - ".default"
								// https://stackoverflow.com/questions/59070216/webpack-file-loader-outputs-object-module
							/>
						))} */}
						<Card
							title="Deltu"
							link="/fr/deltu"
							text="Le robot têtu"
							image={deltuMenu}
						/>
						{/* <Card
							title="eMotion"
							link="/fr/emotion"
							text="Ground Control Station"
							image={deltuMenu}
						/> */}
						<Card title="Lazer" link="/fr/lazer" text="Jeu iPad" image={lazerMenu} />
						<Card
							title="www"
							link="/fr/www"
							text="Sondage interactif"
							image={surveyMenu}
						/>
						<Card
							title="Ramayana"
							link="/fr/ramayana"
							text="Application AR"
							image={ramayanaMenu}
						/>
					</div>
					<div className="text-center">
						<a
							href="https://dribbble.com/krakenwave"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button type="button" className="btn BtnBlack mt-5">
								<div className="text-center mt-2 mx-2 my-2">
									<h3 className="NolineHeight font-weight-bold text-uppercase">
										Plus de projets
									</h3>
								</div>
							</button>
						</a>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default Projets

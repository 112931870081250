import React, { Fragment } from 'react'

const FullImage = ({ image }) => {
	return (
		<Fragment>
			<img className="img-fluid FullImage" src={image} alt={image} />
		</Fragment>
	)
}
export default FullImage

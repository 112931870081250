import React, { Component, Fragment } from 'react'

/** Component */
import {
	Header,
	MyImage,
	MySmallSvg,
	MyVideo,
	Copyright,
	Navigation,
	NavBar,
	SkillsUnityApp,
} from 'components'

/** Images */
import { lazer, lazer1, lazer2, appstore } from 'images'

export default class Lazer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			enlink: '/en/lazer',
			headertitle: 'Lazer',
			headertext:
				"Lazer est un jeu inspiré d'anciens, tels que Vectrex. Le but est de vous plonger dans un monde minimaliste dans lequel vous devez éviter les lasers pour passer les niveaux.",

			videochanel: 'vimeo',
			idvideo: '161785147',

			appstorlink: 'https://itunes.apple.com/fr/app/lazer-game/id1090341180?mt=8',

			copyright: '© 2016  Alexia Lechot / ECAL',
			previouslink: '/fr/deltu',
			nextlink: '/fr/www',
		}
	}
	render() {
		const {
			enlink,
			headertitle,
			headertext,
			appstorlink,
			videochanel,
			idvideo,
			previouslink,
			copyright,
			nextlink,
		} = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBar englishlink={enlink} />

				<div className="bg-white">
					<Header title={headertitle} text={headertext} image={lazer1} />
					<MyVideo channel={videochanel} vimeoId={idvideo} image={lazer2} />
					<MySmallSvg image={appstore} link={appstorlink} />
					<MyImage image={lazer} />
					<SkillsUnityApp />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

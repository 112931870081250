import React, { Component, Fragment } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { NavLink } from 'react-router-dom'

/** Images */
import { logo } from 'images'

export default class NavBarEn extends Component {
	scrollToTop() {
		//scroll at top of the page onClick
		scroll.scrollToTop()
	}
	render() {
		const { frenshlink } = this.props
		return (
			<Fragment>
				<nav className="navbar navbar-expand-lg navbar-dark navi fixed-top">
					<div className="container NavBar">
						<NavLink
							className="navbar-brand"
							to="/en/alexialechot"
							onClick={this.scrollToTop}
						>
							<img src={logo} alt={logo} height="15" />
						</NavLink>
						<div className="navbar-nav ml-auto pt-2 my-auto" id="navbarNav">
							<li
								className="nav-item"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							>
								<NavLink className="nav-link my-auto" to={frenshlink}>
									<h3 className="NolineHeight">FR</h3>
								</NavLink>
							</li>
						</div>
					</div>
				</nav>
			</Fragment>
		)
	}
}

import React, { Component, Fragment } from 'react'

/** Component */
import { HeaderBig, NavBar, FullImage } from 'components'

/** Images */
import { deltu } from 'images'

export default class NotFound extends Component {
	constructor(props) {
		super(props)
		this.state = {
			enlink: '/en',
			headertitle1: 'Erreur 404',
			headertitle2: "Cette page n'existe pas!",
			headertitle3: '🦇',
		}
	}
	render() {
		const { enlink, headertitle1, headertitle2, headertitle3 } = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBar englishlink={enlink} />
				<HeaderBig title1={headertitle1} title2={headertitle2} title3={headertitle3} />
				<FullImage image={deltu} />
			</Fragment>
		)
	}
}

import React, { Component, Fragment } from 'react'

/** Component */
import { HeaderBig, Skills, Projets, Navigation, NavBar } from 'components'

export default class Krakenwave extends Component {
	constructor(props) {
		super(props)
		this.state = {
			enlink: '/en',
			headertitle1: 'Alexia Lechot ',
			headertitle2: 'UX UI Designer &',
			headertitle3: 'Intégratrice Front-end',
			skillstitle: 'Mes skills',
			ptitle: 'Quelques projets',
			previouslink: '/fr/ramayana',
			nextlink: '/fr/deltu',
		}
	}
	render() {
		const {
			enlink,
			headertitle1,
			headertitle2,
			headertitle3,
			skillstitle,
			ptitle,
			previouslink,
			nextlink,
		} = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBar englishlink={enlink} />
				<div id="imageHeader" />
				<HeaderBig title1={headertitle1} title2={headertitle2} title3={headertitle3} />
				<Projets title={ptitle} />
				<div className="mt-6 border-top border-3 border-secondary" />
				<Skills title={skillstitle} />
				<Navigation previous={previouslink} next={nextlink} />
			</Fragment>
		)
	}
}

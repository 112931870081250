import React, { Fragment } from 'react'

/** Component */
import { Card } from 'components'

/** Images */
import { deltuMenu, lazerMenu, ramayanaMenu, surveyMenu } from 'images'

const ProjetsEn = ({ title }) => {
	return (
		<Fragment>
			<div className="pb-6 pt-6">
				{/* TITRE */}
				<div className="container">
					<h2 className="mb-4 text-secondary text-center">{title}</h2>
					<div className="mx-auto" id="CardGroup">
						{/* CARDS */}
						<Card
							title="Deltu"
							link="/en/deltu"
							text="The stubborn robot"
							image={deltuMenu}
						/>
						<Card title="Lazer" link="/en/lazer" text="iPad game" image={lazerMenu} />
						<Card
							title="www"
							link="/en/www"
							text="Interactive survey"
							image={surveyMenu}
						/>
						<Card
							title="Ramayana"
							link="/en/ramayana"
							text="AR Application"
							image={ramayanaMenu}
						/>
					</div>
					<div className="text-center">
						<a
							href="https://dribbble.com/krakenwave"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button type="button" className="btn BtnBlack mt-5">
								<div className="text-center mt-2 mx-2 my-2">
									<h3 className="NolineHeight font-weight-bold text-uppercase">
										More projects
									</h3>
								</div>
							</button>
						</a>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default ProjetsEn

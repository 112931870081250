import React, { Component, Fragment } from 'react'

/** Component */
import { Header, MyImage, Copyright, Navigation, NavBarEn } from 'components'

/** Images */
import { nectarpub, mailingbox } from 'images'

export default class NectarEn extends Component {
	constructor(props) {
		super(props)
		this.state = {
			frlink: '/fr/nectar',
			headertitle: 'Nectar',
			headertext: 'Full graphic identity, underway for a Swiss wine subscription company.',
			copyright: '© 2019 Alexia Lechot',
			previouslink: '/en/alexialechot',
			nextlink: '/en/www',
		}
	}
	render() {
		const { frlink, headertitle, headertext, copyright, previouslink, nextlink } = this.state
		return (
			<Fragment>
				{/* NAVBAR */}
				<NavBarEn frenshlink={frlink} />

				<div className="bg-white">
					<Header title={headertitle} text={headertext} image={nectarpub} />
					<MyImage image={mailingbox} />
					<Copyright text={copyright} />
					<Navigation previous={previouslink} next={nextlink} />
				</div>
			</Fragment>
		)
	}
}

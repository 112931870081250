import React, { Component, Fragment } from 'react'

/** IMG */
import { krakenwavebg, krakenwavebgmobile } from '../../images'

export default class HeaderMain extends Component {
	render() {
		return (
			<Fragment>
				{/* USE IN MY MAIN PAGE KRAKENWAVE ! */}
				<div id="HeaderKraken">
					<img
						className="img-fluid CenterDiv logoHeader"
						src={krakenwavebg}
						alt={krakenwavebg}
					/>
					<img
						className="img-fluid CenterDiv logoHeaderMobile"
						src={krakenwavebgmobile}
						alt={krakenwavebgmobile}
					/>
				</div>
			</Fragment>
		)
	}
}

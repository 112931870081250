import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import Loadable from 'react-loadable'

/** Components */
import {
	Krakenwave,
	Nectar,
	Www,
	Ramayana,
	Deltu,
	eMotion,
	eMotionEn,
	Lazer,
	KrakenwaveEn,
	NectarEn,
	WwwEn,
	RamayanaEn,
	DeltuEn,
	LazerEn,
	Footer,
	NotFound,
} from './components'

// const LazyKrakenwave = Loadable({
// 	loader: () =>
// 		import(/* webpackChunkName: 'krakenwave' */ './components/Features/Krakenwave/Krakenwave'),
// 	loading: () => <h1>Chargement...</h1>,
// 	delay: 2000,
//     timeout: 10000,
// })

export default class App extends Component {
	render() {
		return (
			<Fragment>
				{/* like index.js - default page that are implemented in all pages */}

				<Router>
					<div className="body">
						^
						{/* CONTENT */}
						{/* ROUTER */}
						{/* Select page for each path */}
						<Switch>
							{/* KRAKENWAVE */}

							{/* MENU - NAVBAR - FRANCAIS */}
							<Route exact path="/fr" component={Krakenwave} />
							<Route exact path="/fr/alexialechot" component={Krakenwave} />
							{/* PROJECTS PAGES */}
							<Route exact path="/fr/nectar" component={Nectar} />
							<Route exact path="/fr/www" component={Www} />
							<Route exact path="/fr/ramayana" component={Ramayana} />
							<Route exact path="/fr/deltu" component={Deltu} />
							<Route exact path="/fr/lazer" component={Lazer} />
							<Route exact path="/fr/emotion" component={eMotion} />

							{/* NEUTRAL */}
							<Route exact path="/" component={Krakenwave} />
							<Route exact path="/alexialechot" component={Krakenwave} />
							{/* PROJECTS PAGES */}
							<Route exact path="/nectar" component={Nectar} />
							<Route exact path="/www" component={Www} />
							<Route exact path="/ramayana" component={Ramayana} />
							<Route exact path="/deltu" component={Deltu} />
							<Route exact path="/lazer" component={Lazer} />
							<Route exact path="/emotion" component={eMotion} />

							{/* MENU - NAVBAR - ENGLISH */}
							<Route exact path="/en" component={KrakenwaveEn} />
							<Route exact path="/en/alexialechot" component={KrakenwaveEn} />
							{/* PROJECTS PAGES */}
							<Route exact path="/en/nectar" component={NectarEn} />
							<Route exact path="/en/www" component={WwwEn} />
							<Route exact path="/en/ramayana" component={RamayanaEn} />
							<Route exact path="/en/deltu" component={DeltuEn} />
							<Route exact path="/en/lazer" component={LazerEn} />
							<Route exact path="/en/emotion" component={eMotionEn} />

							{/* 404 */}
							<Route component={NotFound} />
						</Switch>
					</div>
				</Router>

				{/* FOOTER */}
				<Footer />
			</Fragment>
		)
	}
}

import React, { Component, Fragment } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default class Card extends Component {
	constructor(props, context) {
		super(props, context)
		AOS.init({
			once: false,
		})
	}
	componentWillReceiveProps() {
		AOS.refresh()
	}
	scrollToTop() {
		//scroll at top of the page onClick
		scroll.scrollToTop()
	}
	render() {
		const { image, link, title, text } = this.props
		return (
			<Fragment>
				{/* CARDS */}
				<div data-aos="zoom-in" data-aos-duration="2000" data-aos-once="false">
					<div className="mt-2" id="card">
						<div className="CardAnim border-0">
							<img src={image} alt={title} />
							{/* Need a scroll to top - sinon la page téléchargé reste à la même hauteur que celle en cours*/}
							<Link
								className="card-img-overlay overlay"
								to={link}
								onClick={this.scrollToTop}
							>
								<h2 className="card-title text-white">{title}</h2>
								<h4 className="card-text mt-8 text-white">{text}</h4>
							</Link>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

import React, { Fragment } from 'react'

/** Component */
import { Cell } from 'components'

/** Images */
import { arduino, cpp, csharp, unity, xcode, treed, concept, ux, dev } from 'images'

const SkillsDeltu = () => {
	return (
		<Fragment>
			<div className="container my-6">
				{/* TITRE */}
				<h2 className="text-secondary text-center mb-4">Rôle</h2>

				{/* SKILLS */}
				<div className="SectionCellGroup mx-auto">
					<Cell title="Développement de concept" image={concept} />
					<Cell title="Design de produit" image={treed} />
					<Cell title="UX & UI Design" image={ux} />
					<Cell title="Développement de l'application" image={dev} />
					<Cell title="Développement du robot" image={dev} />
					<Cell title="Arduino" image={arduino} />
					<Cell title="Unity" image={unity} />
					<Cell title="Xcode" image={xcode} />
					<Cell title="C++ (arduino)" image={cpp} />
					<Cell title="C#" image={csharp} />
				</div>
			</div>
		</Fragment>
	)
}
export default SkillsDeltu
